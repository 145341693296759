<template>
<div>
    <Title msg="Temas"/>
    <div v-if="loadingThemas">
        <loadingQuiz/>
    </div>

    <div class="row justify-content-md-center" v-else>
        <div class="col-12 col-sm-12 col-md-12 col-lg-10" v-for="(thema, index) in themas" :key="thema.id">
            <div class="cCuadro" v-on:click="openModal(thema.id)" data-toggle="modal" data-target="#roundModal">
                <div class="row d_theme align-items-center">
                    <div class="col- nutemas text-center">{{ index + 1 }}</div>
                    <div class="col">
                        <p class="theme_name text-break">{{ thema.name }}</p>
                        <p class="theme_questions text-break">Cantidad preguntas: {{ thema.questions_count }}</p>
                        <div hidden class="progress row" style="width: calc(100% - 18px); margin-top: 10px; margin-left: 18px;">
                            <div class="progress-bar" role="progressbar" style="width: 25%;" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">
                                25%
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div id="modal">
        <div class="modal fade" id="roundModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" v-show="btnCreate" id="exampleModalLabel">
                            Crear
                        </h5>
                        <h5 class="modal-title" v-show="btnVersus" id="exampleModalLabel">
                            Versus
                        </h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <form ref="formRound" hidden>
                            <div class="form-group">
                                <select class="form-control" v-model="amountQuestions" name="amount-questions">
                                    <option value="0" selected>Numero de preguntas</option>
                                    <option value="10">10</option>
                                    <option value="15">15</option>
                                    <option value="20">20</option>
                                    <option value="25">25</option>
                                </select>
                            </div>
                        </form>
                        <div class="input-group mb-3" v-show="btnVersus">
                            <input type="text" 
                                class="form-control urlInvite" 
                                :value="appDomainFront + 'invite/' + student.user.affiliate_id">
                            <div class="input-group-append">
                                <button class="btn btn-primary btn-sm" type="button" v-on:click="copyText">Copiar</button>
                            </div>
                        </div>
                        <div class="my-4 text-center">
                            <div v-show="btnCreate">
                                <button type="button" class="btn btn-primary btn-lg mr-4" v-on:click="sendVersus">
                                    Versus
                                </button>
                                <button type="button" class="btn btn-primary btn-lg" data-dismiss="modal" v-on:click="startQuiz">
                                    Ronda
                                </button>
                            </div>
                            <div v-show="btnVersus">
                                <button type="button" class="btn btn-secondary btn-lg" data-dismiss="modal" v-on:click="cancelVersus">
                                    Cancelar
                                </button>
                            </div>
                            <div v-show="this.$store.state.versusInviteStatus">
                                <button type="button" class="btn btn-primary btn-lg my-2" data-dismiss="modal" v-on:click="startVersus">
                                    Iniciar ({{ responseTime }})
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</div>
</template>

<script>
import {mapState} from "vuex";
import axios from 'axios';
import loadingQuiz from "@/views/student/constants/loading/theme.vue";
import Title from '@/views/student/constants/title.vue';

export default {
    name: 'coursesShow',
    components: {
        loadingQuiz,
        Title
    },
    data() {
        return {
            course: this.$route.params.url,
            thema: "0",
            amountQuestions: "10",
            copyTextInput: "",
            btnCreate: true,
            btnVersus: false,
            friend: "",
            timerNotification: 30,
            responseTime: 0,
            time: null,
            notificationStatusLocal:true,
        };
    },
    created() {
        this.$store.dispatch("setThemas");
        this.$store.dispatch("setStudent");
        this.versusChannelPrivate();
    },
    methods: {
        restartTimer() {
            this.responseTime = this.timerNotification;
                    const self = this;
                    clearInterval(this.timer);
                    if (this.notificationStatusLocal) {
                        this.timer = setInterval(function () {
                            self.responseTime -= 1;
                            if (self.responseTime <= 0) {
                                self.finishedTime();
                            }
                        }, 1000);
                    }
        },
        finishedTime() {
            this.notificationStatusLocal = false;
            this.$store.state.versusInviteStatus = false;
            clearInterval(this.timer);
        },
        versusChannelPrivate() {
            window.Echo.channel("versus." + this.student.user.id).listen("InviteChanged", (e) => {
                this.restartTimer();
                this.$store.state.versusInvite.id = e.roundVersus;
                this.$store.state.versusInvite.name = e.userName;
                this.$store.state.versusInvite.profile = e.userAvatar;
                this.$store.state.versusInviteStatus = true;
            });
            },
        returnQuiz() {
            const url = this.appDomainApi1 + "round/start"
            axios
                .post(url, {
                    course: this.$route.params.url,
                })
                .then(function (response) {
                    window.location.href = this.appDomainApi1 + "round/" + response.data.url;
                })
                .catch(function (error) {
                    console.log(error);
                });
        },
        startQuiz() {
            if (parseInt(this.amountQuestions)) {
                if (this.course && this.thema) {
                    const url = this.appDomainApi1 + "round/start"
                    axios
                        .post(
                            url, {
                                course: this.$route.params.url,
                                thema: this.thema,
                                amount_questions: "10",
                            },
                        )
                        .then((response) => {
                            this.$router.push({
                                name: "Round",
                                params: {
                                    url: response.data.url
                                },
                            });
                        })
                        .catch(function (error) {
                            console.log(error);
                        });
                } else {
                    alert("Ah ocurrido un error.");
                }
            } else {
                alert("Debe especificar un numero de preguntas a realizar.");
            }
        },
        sendVersus() {
            this.btnCreate = false;
            this.btnVersus = true;
            if (this.course && this.thema && parseInt(this.amountQuestions)) {
                const url = this.appDomainApi1 + "versus/creator"
                axios
                    .post(url, {
                        course: this.course,
                        thema: this.thema,
                        amount_questions: this.amountQuestions,
                    })
                    .then(function () {
                    })
                    .catch(function (error) {
                        console.log(error);
                    });
            } else {
                alert("Debe especificar los datos de la ronda.");
            }
        },
        cancelVersus() {
            this.btnCreate = true;
            this.btnVersus = false;
        },
        openModal(idThema) {
            if (this.btnVersus) {
                this.btnVersus = !this.btnVersus;
                this.btnCreate = true;
            }
            this.thema = idThema;
        },
        copyText() {
            var copyTextarea = document.querySelector('.urlInvite');
            copyTextarea.select();
            try {
                var successful = document.execCommand('copy');
                var msg = successful ? 'successful' : 'unsuccessful';
                console.log(msg)
            } catch (err) {
                console.log(err);
            }
        },
        startVersus(){
            this.$store.state.versusInviteStatus = false;
            this.$router.push({
                name: "Versus",
                params: {
                    url: this.$store.state.versusInvite.id,
                },
            });
        }
    },
    computed: {
        ...mapState([
            'themas',
            'loadingThemas',
            'student'
        ]),
    },
};
</script>

<style>
.text-title{
    font-family: "Ubuntu", Helvetica, Arial;
     font-size: 50px;
}
.d_theme:hover {
    cursor: pointer;
    transition-duration: 100ms;
    box-shadow: 5px 5px 15px -3px #76767687;

}

.d_theme {
    border-radius: 5px;
    padding-top: 15px;
    padding-bottom: 15px;
    background: white;
    margin-left: 0.1px;
    margin-right: 0.1px;
    margin-top: 5px;
    margin-bottom: 10px;
}

.dDetalle:hover {
    color: white;
}

.dDetalle {
    padding-top: 10px;
}

.dDetalle>p {
    margin-top: 97px;
    margin-left: 6px;
    padding: 0px;
    margin-top: 3px;
}
.theme_name{
    font-size: 19px;
    color: #202020;
    line-height: 19px;
    margin-bottom: 0.3rem;
}
.theme_questions{
    font-size: 14px;
    color: #9EA9A7;
    line-height: 14px;
    margin-bottom: 0.3rem;
}
.nutemas {
    color: black;
    margin-left: 16px;
    font-family: inherit;
    font-size: 50px;
}
</style>
